import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

import { ReactComponent as Discount } from 'assets/icons/discount.svg';
import { ReactComponent as List } from 'assets/icons/list.svg';
import { ReactComponent as Lock } from 'assets/icons/lock.svg';
import { ReactComponent as SearchFolder } from 'assets/icons/search-folder.svg';
import { ReactComponent as StockExchange } from 'assets/icons/stock-exchange.svg';
import { ReactComponent as Unbalanced } from 'assets/icons/unbalanced.svg';
import anonymous from 'assets/icons/anonymous.svg';

import aina from 'assets/images/aina-logo-mini.png';

const AdvantagesToggle = () => {
  const [t] = useTranslation('home');

  const [activeIndex, setActiveIndex] = useState(null);

  const toggle = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const items = [
    { svg: <StockExchange /> },
    { svg: <Discount /> },
    { svg: <List /> },
    { svg: <SearchFolder /> },
    { svg: <Lock /> },
    { svg: <Unbalanced /> },
  ];

  return (
    <>
      <div className={`${styles.container} wow fadeInUp`}>
        <div className={styles.titleCol}>
          <p className={`${styles.title} wow fadeInUp`}>
            <span
              dangerouslySetInnerHTML={{
                __html: t('advantages.title'),
              }}
            ></span>
          </p>
        </div>

        {items.map((item, index) => (
          <div
            key={index}
            className={`${styles.toggleContainer} ${activeIndex === index ? styles.active : styles.closing}  wow fadeInUp`}
            onClick={() => toggle(index)}
          >
            <div className={styles.toggleHeader}>
              {item.svg}
              <span>{t(`advantages.header${index + 1}`)}</span>
            </div>
            <div className={styles.toggleBody}>
              <div className={styles.before}>
                <span style={{ backgroundImage: `url(${anonymous})` }}>
                  {t(`advantages.before${index + 1}`)}
                </span>
              </div>
              <div className={styles.after}>
                <span
                  style={{
                    backgroundImage: `url(${aina})`,
                    backgroundSize: '57px 68px',
                  }}
                >
                  {t(`advantages.after${index + 1}`)}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default AdvantagesToggle;
