import { useEffect } from 'react';
import WOW from 'wowjs';

import AdvantagesTable from 'components/home/AdvantagesTable';
import AdvantagesToggle from 'components/home/AdvantagesToggle';
import Features from 'components/home/Features';
import FeaturesRow from 'components/home/FeaturesRow';
import Footer from 'components/home/Footer';
import Form from 'components/home/Form';
import Header from 'components/home/Header';
import Hero from 'components/home/Hero';
import ImageTextBlock from 'components/home/ImageTextBlock';
import MediaLinks from 'components/home/MediaLinks';
import Steps from 'components/home/Steps';
import Tabs from 'components/home/Tabs';

import useWindowDimensions from 'hooks/useWindowDimensions';

const Home = () => {
  const { width } = useWindowDimensions();

  useEffect(() => {
    new WOW.WOW({
      live: true,
    }).init();
  }, []);

  return (
    <div>
      <Header fullDisplay={true} />
      <Hero />
      <MediaLinks />
      <Features />
      <FeaturesRow />
      <ImageTextBlock blockNumber={1} />
      <Tabs />
      <ImageTextBlock blockNumber={2} />
      <ImageTextBlock blockNumber={3} />
      {width < 1100 && <AdvantagesToggle />}
      {width >= 1100 && <AdvantagesTable />}
      <Steps />
      <Form />
      <Footer />
    </div>
  );
};

export default Home;
