class CreditPurchase {
  constructor(params) {
    this.id = params.id;
    this.code = params.id;
    this.insurer = params.insurer;
    this.insurerId = params.insurerId;
    this.paymentStatus = params.paymentStatus;
    this.paymentStatusId = params.paymentStatusId;
    this.price = params.price;
    this.taxes = params.taxes;
    this.total = params.total;
    this.credits = params.credits;
    this.paymentDate = params.paymentDate;
    this.createdAt = params.createdAt;
  }
}

export default CreditPurchase;
