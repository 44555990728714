import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import MUIDialog from '@mui/material/Dialog';

import styles from './styles.module.scss';

import Logo1 from 'assets/images/partners/logo_01.jpg';
import Logo2 from 'assets/images/partners/logo_02.jpg';
import Logo3 from 'assets/images/partners/logo_03.jpg';
import Logo4 from 'assets/images/partners/logo_04.jpg';
import Logo5 from 'assets/images/partners/logo_05.jpg';
import Logo6 from 'assets/images/partners/logo_06.jpg';
import Logo7 from 'assets/images/partners/logo_07.jpg';
import Logo8 from 'assets/images/partners/logo_08.jpg';
import Logo9 from 'assets/images/partners/logo_09.jpg';
import Logo19 from 'assets/images/partners/logo_10.jpg';
import Logo10 from 'assets/images/partners/logo_11.jpg';
import Logo11 from 'assets/images/partners/logo_12.jpg';
import Logo12 from 'assets/images/partners/logo_13.jpg';
import Logo13 from 'assets/images/partners/logo_14.jpg';
import Logo14 from 'assets/images/partners/logo_15.jpg';
import Logo15 from 'assets/images/partners/logo_16.jpg';
import Logo16 from 'assets/images/partners/logo_17.jpg';
import Logo17 from 'assets/images/partners/logo_18.jpg';
import Logo18 from 'assets/images/partners/logo_19.jpg';
import Logo20 from 'assets/images/partners/logo_20.jpg';

const PartnersDialog = ({ open, onClose, ...props }) => {
  const [t] = useTranslation('home');

  function handleOnClose() {
    onClose();
  }

  return (
    <>
      <MUIDialog
        open={open}
        onClose={handleOnClose}
        slotProps={{
          backdrop: {
            className: styles.backdrop,
          },
        }}
        classes={{
          paper: styles.container,
          root: styles.dialog,
        }}
        {...props}
      >
        <i className={styles.closeIcon} onClick={onClose}></i>
        <div className={styles.body}>
          <p className={styles.title}>
            <span
              dangerouslySetInnerHTML={{ __html: t('partners.dialog.title') }}
            ></span>
          </p>
          <div className={styles.logos}>
            <div className={styles.logo}>
              <img src={Logo1} />
            </div>
            <div className={styles.logo}>
              <img src={Logo2} />
            </div>
            <div className={styles.logo}>
              <img src={Logo3} />
            </div>
            <div className={styles.logo}>
              <img src={Logo4} />
            </div>
            <div className={styles.logo}>
              <img src={Logo5} />
            </div>
            <div className={styles.logo}>
              <img src={Logo6} />
            </div>
            <div className={styles.logo}>
              <img src={Logo7} />
            </div>
            <div className={styles.logo}>
              <img src={Logo8} />
            </div>
            <div className={styles.logo}>
              <img src={Logo9} />
            </div>
            <div className={styles.logo}>
              <img src={Logo10} />
            </div>
            <div className={styles.logo}>
              <img src={Logo11} />
            </div>
            <div className={styles.logo}>
              <img src={Logo12} />
            </div>
            <div className={styles.logo}>
              <img src={Logo13} />
            </div>
            <div className={styles.logo}>
              <img src={Logo14} />
            </div>
            <div className={styles.logo}>
              <img src={Logo15} />
            </div>
            <div className={styles.logo}>
              <img src={Logo16} />
            </div>
            <div className={styles.logo}>
              <img src={Logo17} />
            </div>
            <div className={styles.logo}>
              <img src={Logo18} />
            </div>
            <div className={styles.logo}>
              <img src={Logo19} />
            </div>
            <div className={styles.logo}>
              <img src={Logo20} />
            </div>
            <div className={styles.emptySlot}></div>
          </div>
        </div>
      </MUIDialog>
    </>
  );
};

PartnersDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default PartnersDialog;
