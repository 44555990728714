import Footer from 'components/home/Footer';
import Header from 'components/home/Header';
import PublicContainer from 'UI/atoms/PublicContainer';

const CookiesPage = () => {
  return (
    <div>
      <Header />
      <PublicContainer>
        <h1>Política de Cookies</h1>
        <h2>INFORMACIÓN SOBRE LA UTILIZACIÓN DE COOKIES – LEY DE COOKIES</h2>
        <p>
          En esta Web se pueden estar utilizando cookies en algunas páginas. La
          finalidad de dichas cookies es mejorar el servicio que ofrecen a sus
          clientes y a nuestros visitantes. En caso de ser instaladas, el
          administrador de esta Web le solicitará su consentimiento para
          hacerlo, y tendrá opción de aceptarlo.
        </p>
        <p>
          Art. 22 LSSI.- “Los prestadores de servicios podrán utilizar
          dispositivos de almacenamiento y recuperación de datos en equipos
          terminales de los destinatarios, a condición de que los mismos hayan
          dado su consentimiento después de que se les haya facilitado
          información clara y completa sobre su utilización, en particular,
          sobre los fines del tratamiento de los datos, con arreglo a lo
          dispuesto en la Ley Orgánica 15/1999, de 13 de diciembre, de
          Protección de Datos de Carácter Personal, Cuando sea técnicamente
          posible y eficaz, el consentimiento del destinatario para aceptar el
          tratamiento de los datos podrá facilitarse mediante el uso de los
          parámetros adecuados del navegador o de otras aplicaciones, siempre
          que aquél deba proceder a su configuración durante su instalación o
          actualización mediante una acción expresa a tal efecto. Lo anterior no
          impedirá el posible almacenamiento o acceso de índole técnica al solo
          fin de efectuar la transmisión de una comunicación por una red de
          comunicaciones electrónicas o, en la medida que resulte estrictamente
          necesario, para la prestación de un servicio de la sociedad de la
          información expresamente solicitado por el destinatario.”
        </p>
        <p>
          Una «Cookie» es un pequeño archivo que se almacena en el ordenador del
          usuario y nos permite reconocerle, obtener información sobre tus
          hábitos de navegación o personalizar la forma en que se muestra el
          contenido. El conjunto de «cookies» nos ayuda a mejorar la calidad de
          nuestra web, permitiéndonos controlar qué páginas encuentran nuestros
          usuarios útiles y cuáles no.
        </p>
        <p>
          Quedan excluidas del ámbito de aplicación del artículo 22.2 de la
          LSSI:
        </p>
        <ul>
          <li>Cookies de entrada del usuario</li>
          <li>Cookies de autenticación o identificación del usuario</li>
          <li>Cookies de sesión de reproductor multimedia</li>
          <li>Cookies de sesión para equilibrar la carga</li>
          <li>Cookies de personalización de la interfaz de usuario</li>
          <li>
            Determinadas cookies de completamente para intercambiar contenidos
            sociales.
          </li>
        </ul>
        <p>
          Por ello este sitio web puede utilizarlas ya que permiten el
          funcionamiento y la presentación de los servicios ofrecidos en el
          mismo.
        </p>
        <h2>
          ADMINISTRACIÓN DE COOKIES Y REVOCACIÓN DEL CONSENTIMIENTO PRESTADO
        </h2>
        <p>
          Le informamos que usted puede{' '}
          <strong>rechazar el uso e instalación de las cookies</strong> de esta
          web cambiando la configuración de su navegador, pudiendo seguir usando
          este sitio web, también le advertimos que puede verse limitada la
          experiencia de uso de esta web si cambia algunas de las opciones que
          le damos.
        </p>
        <h2>ADMINISTRACIÓN DE COOKIES POR NAVEGADOR</h2>
        <ul>
          <li>
            Google Chrome:
            http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647
          </li>
          <li>
            Explorer:
            http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9
          </li>
          <li>
            Firefox:
            https://support.mozilla.org/es/kb/Protecci%C3%B3n-contra-el-rastreo-mejorada-en-Firefox-para-escritorio
          </li>
          <li>Safari: http://support.apple.com/kb/ph5042</li>
        </ul>
        <p>
          Muchos navegadores permiten activar un modo privado mediante el cual
          las cookies se borran siempre después de su visita. Dependiendo de
          cada navegador, este modo privado puede tener diferentes nombres,
          abajo puede encontrar una lista de los navegadores más comunes y los
          diferentes nombres de este “modo privado”:
        </p>
        <ul>
          <li>Internet Explorer v11.0.205: in private.</li>
          <li>FireFox 0.0.14 y superior: Navegación Privada.</li>
          <li>Google Chrome 84.0.4147.135 y superior: Incógnito.</li>
          <li>Safari 13.1.1: Navegación Privada.</li>
          <li>Opera 10.5 y superior: Navegación Privada.</li>
          <li>Microsoft Edge: 85.0.564.41: in private</li>
        </ul>
        <p>
          Por favor, lea atentamente la sección de ayuda de su navegador para
          conocer más acerca de cómo activar el “modo privado”. Podrá seguir
          visitando nuestra Web aunque su navegador esté en “modo privado”, no
          obstante, la experiencia de usuario puede no ser óptima y algunas
          utilidades pueden no funcionar.
        </p>
        <p>
          Si necesita más información acerca del bloqueo en su equipo de
          cookies, no dude en atender la explicación que se le da en este enlace
          Web:
          http://windows.microsoft.com/es-es/windows-vista/block-or-allow-cookies
        </p>
        <p>
          Aunque las cookies son esenciales para el funcionamiento de internet,
          aportando innumerables ventajas en la prestación de servicios
          interactivos, facilitándole la navegación y el uso de nuestra web, las
          cookies no pueden dañar su equipo y que, a cambio, el que estén
          activadas nos ayudan a identificar y resolver los errores.
        </p>
        <h2>TIPOS DE COOKIES:</h2>
        <p>
          La información que le proporcionamos a continuación le ayudará a
          comprender los diferentes tipos de cookies.
        </p>
        <h4>Según quien sea la entidad que gestione las cookies:</h4>
        <ul>
          <li>
            Cookies propias: son aquellas que se envían al equipo terminal del
            usuario desde un equipo o dominio gestionado por el propio editor y
            desde el que presta el servicio solicitado por el usuario.
          </li>
          <li>
            Cookies de tercero: son aquellas que se envían al equipo terminal
            del usuario desde un equipo o dominio que no es gestionado por el
            editor, sino por otra entidad que trata los datos obtenidos a través
            de las cookies.
          </li>
        </ul>
        <h4>Según su finalidad:</h4>
        <ul>
          <li>
            Cookies técnicas: aquellas que permiten al usuario la navegación y
            la utilización de las diferentes opciones o servicios que en ella
            existen.
          </li>
          <li>
            Cookies de preferencias o personalización: aquellas que permiten
            recordar información para que el usuario acceda al servicio con
            determinadas características que puedan diferenciar su experiencia
            de la de otros usuarios (por ejemplo el idioma).
          </li>
          <li>
            Cookies de análisis o medición: aquellas que permiten al responsable
            el seguimiento y análisis del comportamiento de los usuarios de los
            sitios web a los que están vinculadas.
          </li>
          <li>
            Cookies de publicidad comportamental: son aquellas que almacena
            información del comportamiento de los usuarios obtenida a través de
            la observación continuada de sus hábitos de navegación.
          </li>
        </ul>
        <h4>
          Según el tiempo que permanecen activadas en el equipo terminal podemos
          distinguir
        </h4>
        <ul>
          <li>
            Cookies de sesión: son cookies temporales que permanecen en el
            archivo de cookies de su navegador hasta que abandone la página web,
            por lo que ninguna queda registrada en el disco duro del usuario. La
            información obtenida por medio de estas cookies, sirven para
            analizar pautas de tráfico en la web. A la larga, esto nos permite
            proporcionar una mejor experiencia para mejorar el contenido y
            facilitando su uso.
          </li>
          <li>
            Cookies permanentes: son almacenadas en el disco duro y nuestra web
            las lee cada vez que usted realiza una nueva visita. Una web
            permanente posee una fecha de expiración determinada. La cookie
            dejará de funcionar después de esa fecha. Las utilizamos,
            generalmente, para facilitar los servicios de compra y registro.
          </li>
        </ul>
        <p>
          Además, le informamos que las cookies permiten obtener la siguiente
          información:
        </p>
        <ul>
          <li>Fecha y hora de la última vez que el usuario visitó la Web</li>
          <li>
            Diseño de contenido que el usuario escogió en su primera visita a la
            web
          </li>
          <li>
            Elementos de seguridad que intervienen en el control de acceso a las
            áreas restringidas
          </li>
          <li>Otro tipo de información de determinadas cookies específicas</li>
        </ul>
        <p>
          En todo caso y en cumplimiento de la “Ley de Cookies” Ley 13/2012 de
          30 de marzo, y del art. 22 de la LSSI, nuestra página Web, en caso de
          instalar alguna cookie le solicitará su consentimiento previo a la
          instalación de la misma en el mismo momento que indexe nuestra
          dirección Web.
        </p>
        <h2>5. COOKIES UTILIZADAS POR ESTE SITIO WEB:</h2>
        <h3>Necesaria</h3>
        <p>
          Las cookies necesarias son cruciales para las funciones básicas del
          sitio web y el sitio web no funcionará de la forma prevista sin ellas.
          Estas cookies no almacenan ningún dato de identificación personal.
        </p>
        <table>
          <thead>
            <tr>
              <th>Cookie</th>
              <th>Duración</th>
              <th>Descripción</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>__cf_bm</td>
              <td>1 hora</td>
              <td>
                Cloudflare establece la cookie para respaldar Cloudflare Bot
                Management.
              </td>
            </tr>
            <tr>
              <td>__hssrc</td>
              <td>sesión</td>
              <td>
                Esta cookie es usada por HubSpot cuando cambia la cookie de la
                sesión. La cookie __hssrc fijada como 1 indica que el usuario ha
                reiniciado el navegador, y si la cookie no existe, se asume que
                es una nueva sesión.
              </td>
            </tr>
            <tr>
              <td>__hssc</td>
              <td>1 hora</td>
              <td>
                HubSpot usa esta cookie para realizar un seguimiento de las
                sesiones y para determinar si HubSpot debería aumentar el número
                de sesiones y las marcas horarias en la cookie __hstc.
              </td>
            </tr>
            <tr>
              <td>cookieyes-consent</td>
              <td>1 año</td>
              <td>
                CookieYes establece esta cookie para recordar las preferencias
                de consentimiento de los usuarios, de modo que se respeten sus
                preferencias en las siguientes visitas a este sitio. No recoge
                ni almacena ninguna información personal sobre los visitantes
                del sitio.
              </td>
            </tr>
          </tbody>
        </table>

        <h3>Analítica</h3>
        <p>
          Las cookies analíticas se utilizan para comprender cómo interactúan
          los visitantes con el sitio web. Estas cookies ayudan a proporcionar
          información sobre métricas el número de visitantes, el porcentaje de
          rebote, la fuente de tráfico, etc.
        </p>
        <table>
          <thead>
            <tr>
              <th>Cookie</th>
              <th>Duración</th>
              <th>Descripción</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>_gcl_au</td>
              <td>3 meses</td>
              <td>
                Google Tag Manager establece la cookie para experimentar la
                eficacia publicitaria de los sitios web que utilizan sus
                servicios.
              </td>
            </tr>
            <tr>
              <td>_ga</td>
              <td>1 año 1 mes 4 días</td>
              <td>
                Google Analytics establece esta cookie para calcular los datos
                de los visitantes, de las sesiones y de las campañas y para
                hacer un seguimiento del uso del sitio para el informe de
                análisis del sitio. La cookie almacena la información de forma
                anónima y asigna un número generado aleatoriamente para
                reconocer a los visitantes únicos.
              </td>
            </tr>
            <tr>
              <td>_ga_*</td>
              <td>1 año 1 mes 4 días</td>
              <td>
                Google Analytics sets this cookie to store and count page views.
              </td>
            </tr>
            <tr>
              <td>CLID</td>
              <td>1 año</td>
              <td>
                Microsoft Clarity usa esta cookie para almacenar información
                sobre cómo interactúan los visitantes con la página web. La
                cookie ayuda a proporcionar un informe de análisis. La
                recopilación de datos incluye el número de visitantes, desde
                dónde visitan la página web y las páginas visitadas.
              </td>
            </tr>
            <tr>
              <td>SM</td>
              <td>sesión</td>
              <td>
                Microsoft Clarity usa esta cookie para sincronizar el MUID en
                los dominios de Microsoft.
              </td>
            </tr>
            <tr>
              <td>MR</td>
              <td>7 días</td>
              <td>
                This cookie, set by Bing, is used to collect user information
                for analytics purposes.
              </td>
            </tr>
            <tr>
              <td>_clck</td>
              <td>1 año</td>
              <td>
                Microsoft Clarity usa esta cookie para conservar la
                identificación del usuario de Clarity del navegador y los
                ajustes exclusivos de esa página web. Esto garantiza que las
                acciones realizadas durante las siguientes visitas a la misma
                página web estén vinculadas a la misma identificación del
                usuario.
              </td>
            </tr>
            <tr>
              <td>_hjSessionUser_*</td>
              <td>1 año</td>
              <td>
                Hotjar sets this cookie to ensure data from subsequent visits to
                the same site is attributed to the same user ID, which persists
                in the Hotjar User ID, which is unique to that site.
              </td>
            </tr>
            <tr>
              <td>_hjSession_*</td>
              <td>1 hora</td>
              <td>
                Hotjar sets this cookie to ensure data from subsequent visits to
                the same site is attributed to the same user ID, which persists
                in the Hotjar User ID, which is unique to that site.
              </td>
            </tr>
            <tr>
              <td>_clsk</td>
              <td>1 día</td>
              <td>
                Microsoft Clarity usa esta cookie para almacenar y consolidar
                las visitas a las páginas de un usuario en un único registro de
                sesión.
              </td>
            </tr>
            <tr>
              <td>__hstc</td>
              <td>6 meses</td>
              <td>
                HubSpot usa esta cookie para realizar un seguimiento de los
                visitantes. Contiene el dominio, la marca horaria de la última
                visita, la marca horaria de la visita actual y el número de la
                sesión (incrementa por cada sesión posterior).
              </td>
            </tr>
            <tr>
              <td>hubspotutk</td>
              <td>6 meses</td>
              <td>
                HubSpot usa esta cookie para realizar un seguimiento de los
                visitantes a su página web. Esta cookie se pasa a HubSpot cuando
                se envía el formulario y se utiliza cuando se vuelven a duplicar
                los contactos.
              </td>
            </tr>
            <tr>
              <td>_gid</td>
              <td>1 día</td>
              <td>
                Google Analytics establece esta cookie para almacenar
                información sobre el uso que hacen los visitantes de un sitio
                web, al tiempo que crea un informe analítico sobre el
                rendimiento del sitio. Algunos de los datos recogidos incluyen
                el número de visitantes, su origen y las páginas que visitan de
                forma anónima.
              </td>
            </tr>
            <tr>
              <td>_gat_gtag_UA_*</td>
              <td>1 minuto</td>
              <td>
                Google Analytics sets this cookie to store a unique user ID.
              </td>
            </tr>
            <tr>
              <td>_hjTLDTest</td>
              <td>sesión</td>
              <td>
                Para determinar la ruta de la cookie más genérica que debe
                usarse en lugar del nombre de host de la página, Hotjar usa la
                cookie _hjTLDTest para almacenar diferentes alternativas de
                subcadenas de URL hasta que falle.
              </td>
            </tr>
            <tr>
              <td>vuid</td>
              <td>1 año 1 mes 4 días</td>
              <td>
                Vimeo instala esta cookie para recoger información de
                seguimiento al establecer un ID único para incrustar vídeos en
                el sitio web.
              </td>
            </tr>
          </tbody>
        </table>

        <h3>El rendimiento</h3>
        <p>
          Las cookies de rendimiento se utilizan para comprender y analizar los
          índices de rendimiento clave del sitio web, lo que ayuda a
          proporcionar una mejor experiencia de usuario para los visitantes.
        </p>
        <table>
          <thead>
            <tr>
              <th>Cookie</th>
              <th>Duración</th>
              <th>Descripción</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>SRM_B</td>
              <td>1 año 24 días</td>
              <td>
                Usada por Microsoft Advertising como una identificación única
                para los visitantes.
              </td>
            </tr>
          </tbody>
        </table>

        <h3>Anuncio</h3>
        <p>
          Las cookies publicitarias se utilizan para entregar a los visitantes
          anuncios personalizados basados en las páginas que visitaron antes y
          analizar la efectividad de la campaña publicitaria.
        </p>
        <table>
          <thead>
            <tr>
              <th>Cookie</th>
              <th>Duración</th>
              <th>Descripción</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>test_cookie</td>
              <td>15 minutos</td>
              <td>
                doubleclick.net establece esta cookie para determinar si el
                navegador del usuario es compatible con las cookies.
              </td>
            </tr>
            <tr>
              <td>MUID</td>
              <td>1 año 24 días</td>
              <td>
                Bing establece esta cookie para reconocer los navegadores únicos
                que visitan los sitios de Microsoft. Esta cookie se utiliza para
                publicidad, análisis del sitio y otras operaciones.
              </td>
            </tr>
            <tr>
              <td>ANONCHK</td>
              <td>10 minutos</td>
              <td>
                Bing usa la cookie ANONCHK para almacenar la identificación de
                la sesión del usuario y verificar los clics en los anuncios en
                el motor de búsqueda de Bing. La cookie también ayuda la
                elaboración de informes y con la personalización.
              </td>
            </tr>
            <tr>
              <td>IDE</td>
              <td>1 año 24 días</td>
              <td>
                Las cookies de Google DoubleClick IDE almacenan información
                sobre el uso que el usuario hace del sitio web para presentarle
                anuncios relevantes según su perfil.
              </td>
            </tr>
          </tbody>
        </table>
        <h3>Sin categorizar</h3>
        <p>
          Otras cookies no categorizadas son las que se están analizando y aún
          no se han clasificado en una categoría.
        </p>
        <table>
          <thead>
            <tr>
              <th>Cookie</th>
              <th>Duración</th>
              <th>Descripción</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>_cfuvid</td>
              <td>sesión</td>
              <td>Description is currently not available.</td>
            </tr>
          </tbody>
        </table>
      </PublicContainer>
      <Footer />
    </div>
  );
};

export default CookiesPage;
