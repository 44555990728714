import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import Logo1 from 'assets/images/media/abc.png';
import Logo2 from 'assets/images/media/larazon.png';
import Logo3 from 'assets/images/media/el-periodico.png';
import Logo4 from 'assets/images/media/la-vanguardia.png';
import Logo5 from 'assets/images/media/edh.png';
import Logo6 from 'assets/images/media/ecd.png';
import Logo7 from 'assets/images/media/diario-financiero.png';
import Logo8 from 'assets/images/media/el-mundo-financiero.png';
import Logo9 from 'assets/images/media/servimedia.png';

const MediaLinks = () => {
  const [t] = useTranslation('home');

  return (
    <>
      <div className={styles.mediaLinks}>
        <div className={styles.container}>
          <p className={`${styles.title} wow fadeIn`}>
            <span
              dangerouslySetInnerHTML={{
                __html: t('media.title'),
              }}
            ></span>
          </p>
          <div className={styles.images}>
            <div className={`${styles.image} wow fadeIn`} data-wow-delay="0.1s">
              <a href={t('media.link1')} rel="noreferrer" target="_blank">
                <img src={Logo1} />
              </a>
            </div>
            <div className={`${styles.image} wow fadeIn`} data-wow-delay="0.2s">
              <a href={t('media.link2')} rel="noreferrer" target="_blank">
                <img src={Logo2} />
              </a>
            </div>
            <div className={`${styles.image} wow fadeIn`} data-wow-delay="0.3s">
              <a href={t('media.link3')} rel="noreferrer" target="_blank">
                <img src={Logo3} />
              </a>
            </div>
            <div className={`${styles.image} wow fadeIn`} data-wow-delay="0.4s">
              <a href={t('media.link4')} rel="noreferrer" target="_blank">
                <img src={Logo4} />
              </a>
            </div>
            <div className={`${styles.image} wow fadeIn`} data-wow-delay="0.5s">
              <a href={t('media.link5')} rel="noreferrer" target="_blank">
                <img src={Logo5} />
              </a>
            </div>
            <div className={`${styles.image} wow fadeIn`} data-wow-delay="0.6s">
              <a href={t('media.link6')} rel="noreferrer" target="_blank">
                <img src={Logo6} />
              </a>
            </div>
            <div className={`${styles.image} wow fadeIn`} data-wow-delay="0.7s">
              <a href={t('media.link7')} rel="noreferrer" target="_blank">
                <img src={Logo7} />
              </a>
            </div>
            <div className={`${styles.image} wow fadeIn`} data-wow-delay="0.8s">
              <a href={t('media.link8')} rel="noreferrer" target="_blank">
                <img src={Logo8} />
              </a>
            </div>
            <div className={`${styles.image} wow fadeIn`} data-wow-delay="0.9s">
              <a href={t('media.link9')} rel="noreferrer" target="_blank">
                <img src={Logo9} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MediaLinks;
