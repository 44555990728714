import { Link } from 'react-router-dom';

import Footer from 'components/home/Footer';
import Header from 'components/home/Header';
import PublicContainer from 'UI/atoms/PublicContainer';

const LegalNoticePage = () => {
  return (
    <div>
      <Header />
      <PublicContainer>
        <h1>Aviso Legal</h1>
        <h2>¿Quiénes somos?</h2>
        <p>
          AinaMarket es una marca comercial utilizada para ofrecer servicios de
          correduría de seguros proporcionados por{' '}
          <strong>Aina Markets S.L.</strong>, cuya sede se encuentra en C/
          Nanclares de Oca 18 6º 2ª, 28022 Madrid. Nuestro CIF es B-56450315, y
          estamos inscritos en el Registro Mercantil de Madrid al tomo 45832,
          folio 31, hoja M-805504, inscripción 1. Puedes ponerte en contacto con
          nosotros enviando un correo electrónico a{' '}
          <a href="mailto:aina@aina.market">aina@aina.market</a>.
        </p>
        <p>
          AinaMarket está registrada como corredor de seguros conforme al Real
          Decreto-ley 3/2020, de 4 de febrero, en la Dirección General de
          Seguros y Fondos de Pensiones con el número J-4285. Puedes consultar
          nuestros datos completos en el Punto Único de información de
          mediadores de seguros en{' '}
          <a href="www.dgsfp.mineco.es">www.dgsfp.mineco.es</a>. Contamos con un
          seguro de responsabilidad civil profesional con CGPA Europe, que cubre
          los riesgos derivados de nuestra actividad como corredores de seguros,
          según lo estipulado en la legislación vigente.
        </p>
        <p>
          Nuestros servicios están destinados a particulares, profesionales
          mayores de 18 años y empresas residentes en España. Estas condiciones
          se aplican tanto a la web como a la plataforma de AinaMarket.
        </p>
        <h2>Servicios de AinaMarket</h2>
        <h3>Mediación en la Contratación de Seguros</h3>
        <p>
          AinaMarket ofrece un servicio de mediación en la contratación de
          seguros, proporcionando asesoramiento independiente, profesional e
          imparcial basado en un análisis objetivo y personalizado de tu perfil
          y necesidades. Al suscribir un contrato de mediación de seguros con
          nosotros, te ayudaremos a encontrar la póliza que mejor se adapte a
          tus necesidades, cumpliendo con la normativa de distribución de
          seguros aplicable.
        </p>
        <p>
          Te proporcionaremos asistencia e información sobre la póliza
          contratada siempre que lo solicites. AinaMarket actúa en
          representación de sus clientes durante la prestación del servicio de
          mediación. Disponemos de una política interna de transparencia y
          gobernanza cuyos principios generales de actuación puedes conocer en
          nuestra web.
        </p>
        <p>
          Toda la información previa sobre nuestro servicio se encuentra
          disponible en nuestra web y se proporciona a los clientes antes de la
          contratación. Si prefieres recibir una copia en papel de esta
          información, puedes solicitarlo sin coste enviando un correo
          electrónico a <a href="mailto:aina@aina.market">aina@aina.market</a>.
        </p>
        <p>
          Puedes desistir del servicio de correduría de AinaMarket en un plazo
          de 14 días desde la aceptación de las condiciones sin necesidad de
          justificar el motivo y sin penalización, contactando con nosotros en{' '}
          <a href="mailto:aina@aina.market">aina@aina.market</a>.
        </p>
        <h3>Registro y Seguridad</h3>
        <p>
          Los usuarios autorizados pueden registrarse en{' '}
          <Link to={'/login'}>
            <span>aina.market/login</span>
          </Link>{' '}
          creando un perfil con su email y contraseña. La contraseña debe tener
          una longitud mínima de 8 caracteres, incluyendo mayúsculas, minúsculas
          y números. Aplicamos medidas de seguridad como el almacenamiento
          encriptado de contraseñas, el bloqueo de la cuenta tras varios
          intentos fallidos y el cierre automático de la sesión tras un periodo
          de inactividad.
        </p>
        <h3>Comparativa de Seguros</h3>
        <p>
          AinaMarket muestra las ofertas de cotización disponibles para tu
          perfil y necesidades concretas, ordenadas por preferencia del cliente
          y acompañadas por un sistema de puntuación que evalúa las coberturas y
          parámetros relevantes de cada cotización. Dispondrás de información
          completa sobre cada oferta, incluyendo la identificación de la
          compañía aseguradora, el detalle de las coberturas, el documento de
          información sobre el producto (IPID), el precio final incluyendo
          impuestos y cualquier otra información relevante para la contratación.
        </p>
        <p>
          La compañía aseguradora emitirá la póliza de seguro y procederá al
          cobro de la prima, detallando la participación de AinaMarket como
          mediador. Aunque nuestro servicio se presta principalmente online,
          podemos ponernos en contacto contigo telefónicamente si es necesario
          para completar la prestación del servicio de mediación.
        </p>
        <h3>Atención al Cliente y Contacto</h3>
        <p>
          Para cualquier duda o asistencia, puedes contactar con nuestro
          servicio de Atención al Cliente mediante chat, solicitud de llamada
          gratuita o email en{' '}
          <a href="mailto:aina@aina.market">aina@aina.market</a>. También puedes
          contactar con nosotros en la sección “Contáctanos” de nuestra web o
          directamente desde tu área privada de cliente.
        </p>
        <h3>Quejas y Reclamaciones</h3>
        <p>
          Si tienes alguna queja sobre nuestro servicio, puedes presentar una
          queja formal ante nuestro departamento de Atención al Cliente mediante
          el formulario disponible en nuestra web, por correo postal a C/
          Nanclares de Oca nº 18 - 6º 2ª, 28022 Madrid, o a través de nuestro
          Defensor del Cliente gestionado por Gabinete de Apoyo a los Mediadores
          S.L., contactando por teléfono al 913568825 o por correo postal a
          Calle Segundo Mata nº 1, Planta 2, Oficina 2, 28224 Pozuelo de
          Alarcón, Madrid.
        </p>
        <p>
          El Defensor del Cliente actúa con independencia y autonomía, y sus
          decisiones favorables al reclamante vincularán a AinaMarket. Si no
          quedas satisfecho con la resolución, puedes acudir al Servicio de
          Protección al Asegurado de la Dirección General de Seguros y Fondos de
          Pensiones.
        </p>
        <h2>Coste del Servicio</h2>
        <p>
          El servicio de mediación de AinaMarket es gratuito para los clientes.
          Obtenemos nuestros beneficios de las comisiones cobradas a las
          compañías aseguradoras cuyos productos mediamos. Estas comisiones
          están incluidas en la prima de los seguros mediados. AinaMarket no
          recibe en ningún caso el importe de la prima de los seguros.
        </p>
        <h2>Responsabilidad por los Datos Suministrados</h2>
        <p>
          Los datos proporcionados por los clientes son fundamentales para
          evaluar el riesgo y ofrecer un asesoramiento personalizado. Si los
          datos no son correctos, puede afectar tanto a los presupuestos previos
          como a la validez de la póliza contratada. Los datos personales serán
          tratados conforme a nuestra Política de Privacidad.
        </p>
        <h2>Uso de la Web y Propiedad Intelectual</h2>
        <p>
          El acceso y uso de la web de AinaMarket está sujeto a condiciones
          legales y políticas de privacidad. Los derechos de propiedad
          intelectual sobre la web y sus contenidos pertenecen a Aina Markets
          S.L. Está prohibida la reproducción, distribución o uso comercial sin
          autorización expresa.
        </p>
        <h2>Modificaciones y Legislación Aplicable</h2>
        <p>
          AinaMarket se reserva el derecho de modificar la presentación,
          configuración y contenidos de la web en cualquier momento. Este aviso
          legal se actualizó por última vez en junio de 2024 y está sujeto a la
          legislación española.
        </p>
        <h2>Contacto</h2>
        <p>Para más información o consultas:</p>
        <ul>
          <li>
            <strong>Dirección postal:</strong> Calle Nanclares de Oca 18 6º 2ª,
            28022 Madrid
          </li>
          <li>
            <strong>Teléfono:</strong>{' '}
            <a href="tel:0034647313462">+34 647 313 462</a>
          </li>
          <li>
            <strong>Email:</strong>{' '}
            <a href="mailto:aina@aina.market">aina@aina.market</a>
          </li>
        </ul>
        <p>
          Te recordamos que puedes acceder a la plataforma de resolución de
          litigios en línea de la Unión Europea a través de este enlace.
        </p>
      </PublicContainer>
      <Footer />
    </div>
  );
};

export default LegalNoticePage;
