class CreditConsumption {
  constructor(params) {
    this.id = params.id;
    this.project = params.project;
    this.projectId = params.projectId;
    this.company = params.company;
    this.companyId = params.companyId;
    this.cif = params.cif;
    this.insurerId = params.insurerId;
    this.insurer = params.insurer;
    this.branch = params.branch;
    this.branchId = params.branchId;
    this.date = params.date;
    this.credits = params.credits;
  }
}

export default CreditConsumption;
