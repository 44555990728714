import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

import lifeIcon from 'assets/icons/features-background-life.svg';
import accidentIcon from 'assets/icons/features-background-accident.svg';
import healthIcon from 'assets/icons/features-background-health.svg';

import cloud1Image from 'assets/images/features-background-cloud-1.svg';
import cloud2Image from 'assets/images/features-background-cloud-2.svg';

const FeaturesRow = () => {
  const [t] = useTranslation('home');

  return (
    <>
      <div className={`${styles.container} wow fadeInUp`}>
        <img
          className={styles.image1}
          src={cloud1Image}
          width="76"
          height="135"
        />
        <img
          className={styles.image2}
          src={cloud2Image}
          width="135"
          height="190"
        />

        <div className={styles.row}>
          <div className={styles.titleCol}>
            <p className={`${styles.title} wow fadeInUp`}>
              <span
                dangerouslySetInnerHTML={{
                  __html: t('featuresBackground.title'),
                }}
              ></span>
            </p>
          </div>
          <div className={styles.itemsCol}>
            <div className={styles.items}>
              <div className={`${styles.item} wow fadeInUp`}>
                <div className={styles.image}>
                  <img src={lifeIcon} width="118" height="109" />
                </div>
                <p>{t('featuresBackground.item1')}</p>
              </div>
              <div
                className={`${styles.item} wow fadeInUp`}
                data-wow-delay="0.2s"
              >
                <div className={styles.image}>
                  <img src={accidentIcon} width="115" height="130" />
                </div>
                <p>{t('featuresBackground.item2')}</p>
              </div>
              <div
                className={`${styles.item} wow fadeInUp`}
                data-wow-delay="0.4s"
              >
                <div className={styles.image}>
                  <img src={healthIcon} width="120" height="120" />
                </div>
                <p>{t('featuresBackground.item3')}</p>
              </div>
            </div>
            <p
              className={`${styles.text} wow fadeInUp`}
              data-wow-delay="0.6s"
              dangerouslySetInnerHTML={{
                __html: t('featuresBackground.text'),
              }}
            ></p>
            <p
              className={`${styles.text} wow fadeInUp`}
              data-wow-delay="0.6s"
              dangerouslySetInnerHTML={{
                __html: t('featuresBackground.text2'),
              }}
            ></p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturesRow;
