import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

import phoneImage from 'assets/icons/phone-icon.svg';
import emailImage from 'assets/icons/mail-icon.svg';

const Footer = () => {
  const [t] = useTranslation('home');

  return (
    <>
      <div className={styles.footer}>
        <div className={styles.footerWrapper}>
          <div className={styles.copyright}>
            <p className={styles.title}>{t('footer.title')}</p>
          </div>

          <ul className={styles.nav}>
            <li className={styles.navLink}>
              <Link to={'/legal-notice'}>
                <span>{t('footer.link1Title')}</span>
              </Link>
            </li>
            <li className={styles.navLink}>
              <Link to={'/privacy-policy'}>
                <span>{t('footer.link2Title')}</span>
              </Link>
            </li>
            <li className={styles.navLink}>
              <Link to={'/cookies'}>
                <span>{t('footer.link3Title')}</span>
              </Link>
            </li>
          </ul>

          <div className={styles.contact}>
            <a href={t('footer.phoneLink')}>
              <img src={phoneImage} width="24" /> {t('footer.phone')}
            </a>
            <a href={t('footer.mailLink')}>
              <img src={emailImage} width="25" /> {t('footer.mail')}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
