import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import styles from './styles.module.scss';
import logo from 'assets/aina-logo.png';
import MenuImage from 'assets/icons/menu-icon.svg';
import MenuImageActive from 'assets/icons/menu-icon-active.svg';
import InfoImage from 'assets/icons/info-icon.svg';

const Header = ({ fullDisplay }) => {
  const [t] = useTranslation('home');
  const [isActive, setIsActive] = useState(false);

  const toggleActive = () => {
    setIsActive(!isActive);
  };

  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      const header = document.querySelector('.header');

      if (header && scrollTop > header.offsetTop) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    const handleResize = () => {
      setIsActive(false);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const body = document.querySelector('body');
    if (isActive) {
      body.classList.add('locked');
    } else {
      body.classList.remove('locked');
    }
  }, [isActive]);

  return (
    <>
      <div className={`header ${styles.header} ${isFixed ? styles.fixed : ''}`}>
        <div className={styles.headerWrapper}>
          <div className={styles.logo}>
            <Link to={'/'}>
              <img src={logo} alt="Aina" width={261} height={64} />
            </Link>
          </div>
          <div
            className={`${styles.menuWrapper} ${isActive ? styles.isActive : ''}`}
          >
            <ul className={`${styles.nav} ${styles.mainNav}`}>
              {fullDisplay && (
                <>
                  <li className={styles.navLink}>
                    <a href="#rules" onClick={toggleActive}>
                      {t('header.changeRules')}
                    </a>
                  </li>
                  <li className={styles.navLink}>
                    <a href="#benefits" onClick={toggleActive}>
                      {t('header.benefits')}
                    </a>
                  </li>
                  <li className={styles.navLink}>
                    <a href="#steps" onClick={toggleActive}>
                      {t('header.steps')}
                    </a>
                  </li>
                </>
              )}
              <li
                className={`${styles.navLink} ${styles.navLinkAccess} ${styles.navLinkXS}`}
              >
                <Link to={'/login'}>{t('header.login')}</Link>
              </li>
              {fullDisplay && (
                <li className={`${styles.navLinkXS} ${styles.moreInfoLink}`}>
                  <a href="#more-info" onClick={toggleActive}>
                    {t('header.moreInfo')}
                  </a>
                </li>
              )}
            </ul>

            <ul className={styles.legal}>
              <li>
                <Link to={'/legal-notice'} onClick={toggleActive}>
                  <span>{t('header.link1Title')}</span>
                </Link>
              </li>
              <li>
                <Link to={'/privacy-policy'} onClick={toggleActive}>
                  <span>{t('header.link2Title')}</span>
                </Link>
              </li>
              <li>
                <Link to={'/cookies'} onClick={toggleActive}>
                  <span>{t('header.link3Title')}</span>
                </Link>
              </li>
            </ul>
          </div>

          <ul className={styles.nav}>
            <li>
              <Link to={'/login'}>{t('header.access')}</Link>
            </li>
            {fullDisplay && (
              <li>
                <a href="#more-info" className={styles.moreInfoLink}>
                  {t('header.moreInfo')}
                </a>
              </li>
            )}
          </ul>

          <ul className={styles.navXS}>
            <li
              className={`${styles.tabLink} ${isActive ? styles.isActiveMenu : ''}`}
              onClick={toggleActive}
            >
              {isActive === true && (
                <>
                  <img
                    src={MenuImageActive}
                    alt={t('header.moreInfo')}
                    width="40"
                    height="40"
                  />
                </>
              )}

              {isActive === false && (
                <>
                  <img
                    src={MenuImage}
                    alt={t('header.moreInfo')}
                    width="40"
                    height="40"
                  />
                </>
              )}
            </li>
            {fullDisplay && (
              <li>
                <a href="#more-info">
                  <img
                    src={InfoImage}
                    alt={t('header.moreInfo')}
                    width="40"
                    height="40"
                  />
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

Header.propTypes = {
  fullDisplay: PropTypes.bool,
};

export default Header;
