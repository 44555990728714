import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const CreditsCost = ({ children }) => (
  <div className={styles.wrapper}>
    <i className={styles.icon}></i>{' '}
    <span
      className={styles.text}
      dangerouslySetInnerHTML={{ __html: children }}
    ></span>
  </div>
);

CreditsCost.propTypes = {
  children: PropTypes.node,
};

export default CreditsCost;
